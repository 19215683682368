/* Footer.css */
footer {
    background: white;
    padding: 1em 1em;
    text-align: center;
}

footer span {
    font-size: clamp(12px, 3vw, 16px);
}

footer span a {
    color: crimson;
    text-decoration: none;
}

footer span a:hover {
    text-decoration: underline;
}