/* Skills.css */
.skills-content {
    display: flex;
    justify-content: space-between;
}

.skills .skills-content .column {
    flex: 1;
    margin: 0 15px;
}

.skills .skills-content .left .text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.skills .skills-content .left p {
    text-align: justify;
}

.skills .skills-content .right .bars {
    margin-bottom: 15px;
}

.skills .skills-content .right .info {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: space-between;
}

.skills .skills-content .right .span {
    font-weight: 600;
    font-size: 18px;
}

.skills .skills-content .right .line {
    height: 5px;
    width: 100%;
    background: lightgrey;
    position: relative;
}

.skills .skills-content .right .line::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background: crimson;
}

.skills-content .right .java::before {
    width: 80%;
}

.skills-content .right .python::before {
    width: 80%;
}

.skills-content .right .js::before {
    width: 80%;
}

.skills-content .right .mysql::before {
    width: 70%;
}

.skills-content .right .css::before {
    width: 70%;
}
.skills-content .right .others::before {
    width: 50%;
}

@media (max-width: 725px) {
    .skills .skills-content {
        flex-direction: column;
        align-content: center;
    }

    .skills .skills-content .column.right{
        margin-top: 2em;
    }
}