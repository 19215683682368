/* Projects.css */
.projects .projects-content a:hover {
    color: crimson;
    background: white;
    border: 2px solid white;
}

.projects .projects-content a {
    display: inline-block;
    background: crimson;
    color: white;
    font-size: clamp(8px, 1em, 16px);
    font-weight: 600;
    padding: 0.5em 1em;
    margin-top: 1.5em;
    border-radius: 6px;
    border: 2px solid crimson;
    transition: all 0.3s ease;
}

.projects-content .box .icon-container {
    display: block;
}

.projects-content .box .icon-container i{
    cursor: pointer;
    padding-top: 0.5em;
}

.projects-content .box .icon-container i:hover{
    color: white;
    transition: all 0.3s ease;
}