/* Home.css */
.home {
    display: flex;
    background: url(silla.jpg) no-repeat center;
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    color: crimson;
    min-height: 500px;
    font-family: "Ubuntu", sans-serif;
}

.home .max-width {
    margin: auto 0 auto 40px;
}

.home .contenido-principal .texto1 {
    font-size: clamp(18px, 4vw, 35px);
}

.home .contenido-principal .texto2 {
    font-size: clamp(38px, 8vw, 70px);
    font-weight: 600;
    margin-left: -3px;
}

.home .contenido-principal .texto3 {
    font-size: clamp(18px, 4vw, 35px);
    margin: 5px 0;
}

.home .contenido-principal .texto3 span {
    font-size: clamp(38px, 8vw, 70px);
    color: rgb(83, 83, 83);
    font-weight: 700;
}

.home .contenido-principal .contact {
    display: inline-block;
    background: crimson;
    color: white;
    font-size: clamp(16px, 3vw, 26px);
    padding: 0.5em 0.5em;
    margin-top: 1em;
    border-radius: 6px;
    border: 2px solid crimson;
    transition: all 0.3s ease;
}

.home .contenido-principal .contact:hover {
    color: crimson;
    background: none;
}