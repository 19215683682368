@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

/* estilo de todas las secciones */
.max-width {
  max-width: 1300px;
  padding: 0 5em;
  margin: auto;
}

section {
  padding: 12vh 0 0 0;
}

section .title {
  position: relative;
  text-align: center;
  font-size: clamp(24px, 4.5vw, 60px);
  font-weight: 500;
  padding-bottom: 1em;
  font-family: "Ubuntu", sans-serif;
}

.about,
.projects,
.services,
.skills,
.contact,
footer {
  font-family: "Poppins", sans-serif;
}

.about .about-content,
.projects .projects-content,
.services .serv-content,
.skills .skills-content,
.contact .contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  font-size: clamp(14px, 1.8vw, 16px);
}

.projects .projects-content,
.services .serv-content {
  background-color: black;
  padding: 1em 1em 0 1em;
  border-radius: 15px;
}

.projects .projects-content div{
  flex-basis: content;
}

.card {
  width: 100vw;
  background: rgb(26, 26, 26);
  text-align: center;
  border-radius: 6px;
  padding: 1em 4em 2em 4em;
  transition: all 0.3s ease;
  color: white;
  margin: 0 0 1em 0;
}

.card .box {
  transition: all 0.3s ease;
}

.card:hover .box {
  transform: scale(1.08);
}

.card i {
  color: white;
  font-size: clamp(30px, 6vw, 50px);
  padding: 0.5em;
  transition: color 0.3s ease;
}

.card:hover i {
  color: crimson;
}

.card .text {
  color: crimson;
  font-size: clamp(20px, 1.5em, 25px);
  font-weight: 600;
  margin: 10px 0 7px 0;
}

@media (max-width: 950px) {
  .max-width {
    padding: 0 1em;
  }

  .card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 690px) {
  .max-width {
    padding: 0 2em;
  }

  .card {
    width: 100%;
  }
}