/* Navbar.css */
.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 1em 0;
    font-family: "Ubuntu", sans-serif;
    transition: all 0.3 ease;
}

.navbar .max-width {
    padding: 0 1em;
}

.navbar.sticky {
    padding: 15px 0;
    background: crimson;
}

.navbar .max-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar .logo a {
    color: rgb(83, 83, 83);
    font-size: clamp(16px, 4vw, 35px);
    font-weight: 600;
    white-space: nowrap;
}

.navbar.sticky .logo a span {
    color: black;
    transition: color 0.3s ease;
}

.navbar.sticky .logo a {
    color: white;
    transition: color 0.3s ease;
}

.navbar .logo a span {
    color: crimson;
}

.navbar .menu li {
    list-style: none;
    display: inline-block;
}

.navbar .menu li a {
    color: rgb(83, 83, 83);
    font-size: clamp(10px, 4vw, 18px);
    font-weight: 500;
    margin-left: 1em;
    transition: color 0.3s ease;
}

.navbar.sticky .menu li a {
    color: white;
    font-size: clamp(10px, 4vw, 18px);
    font-weight: 500;
    margin-left: 1em;
    transition: color 0.3s ease;
}

.navbar .menu li a:hover {
    color: crimson;
}

.navbar.sticky .menu li a:hover {
    color: black;
    transition: color 0.3s ease;
}

/* estilo del boton de menu */
.menu-btn {
    color: #535353;
    font-size: 30px;
    cursor: pointer;
    display: none;
}

.navbar.sticky .menu-btn {
    color: black;
}

.copy-success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: crimson;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    /* Asegura que el mensaje esté por encima de otros elementos */
    animation: fadeOut 1s ease-out 1s forwards;
}

.navbar .menu .language-select {
    color: #535353;
    font-size: clamp(10px, 4vw, 18px);
    background: transparent;
    border: none;
    margin-left: 1em;
    padding: 0.5em;
    cursor: pointer;
    transition: color 0.3s ease;
}

.navbar.sticky .menu .language-select {
    color: white;
}

.navbar .menu .language-select:hover {
    color: crimson;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@media (max-width: 1035px) {
    .menu-btn {
        display: block;
        margin-left: 0.5em;
        z-index: 999;
    }

    .menu-btn i.active:before {
        content: "\f00d";
        color: white;
    }

    .menu-btn:hover i.active:before {
        color: crimson;
        font-size: 1.1em;
        transition: all 0.4s ease;
    }

    .navbar .menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 5rem;
        transition: all 0.4s ease;
    }

    .navbar .menu.active {
        left: 0;
    }

    .navbar.sticky .menu.active li a {
        font-size: 1.5em;
        margin-left: 20px 0;
    }

    .navbar.sticky .menu.active li a:hover {
        color: crimson;
    }

    .navbar .menu li {
        display: block;
    }

    .navbar .menu li a {
        color: white;
        display: inline-block;
        margin: 1em 1em;
        font-size: clamp(20px, 4vw, 24px);
    }

    .navbar .menu .language-select {
        color: white;
        margin: 0.1em 0.1em;
        font-weight: 400;
        font-size: clamp(20px, 4vw, 24px);
    }
}