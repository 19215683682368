/* Socials.css */
.socials {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 6px;
    border: 2px solid crimson;
    background-color: crimson;
    font-family: "Ubuntu", sans-serif;
    transition: all 0.3 ease;

    position: fixed;
    z-index: 998;
    padding: 0.4em 0.65em;
    margin-top: 15em;
}

.icon-container {
    position: relative;
    display: flex;
    align-items: center;
}

.socials i {
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin: 0.5em 0;
    transition: color 0.3s, transform 0.3s;
}

.socials i:hover {
    color: black;
    transform: scale(1.3);
}

#typed-phone {
    color: white;
    position: absolute;
    white-space: nowrap;
    margin-left: 2.2em;
    background: crimson;
    padding: 0.3em;
    border-radius: 8px;
    transition: all 0.3s ease;
}

#typed-phone:hover {
    color: black;
    transform: scale(1.03);
}

/* Socials, quiero ponerlo a la izq del botón */
@media (max-width: 1035px) {
    .socials {
        display: flex;
        flex-direction: row;
        align-items: center;

        position: relative;
        margin-top: 0;
        margin-left: 10vw;
        padding: 0.4em 0.4em;
    }

    .socials i {
        color: white;
        cursor: pointer;
        margin: 0px 0.5em;
        transition: color 0.3s, transform 0.3s;
    }

    .navbar.sticky .socials {
        background: white;
    }

    .navbar.sticky .socials i {
        color: black;
        cursor: pointer;
        margin: 0 0.5em;
        transition: color 0.3s, transform 0.3s;
    }

    .navbar.sticky .socials i:hover {
        color: crimson;
        transform: scale(1.3);
    }

    .socials.active {
        position: fixed;
        margin-left: 0;
        top: 80%; /* Posiciona el elemento al 50% del alto del contenedor */
        left: 50%; /* Posiciona el elemento al 50% del ancho del contenedor */
        gap: 18vw;
        transform: translate(-50%, -50%); /* Ajusta la posición para centrar exactamente el elemento */
        transition: left, 0.3s ease; /*VIENE DE LA DERECHA ABAJO ARREGLAR!*/

        background: none;
        border: 0;
    }

    .socials.active i {
        font-size: 10vw;
    }
    
    .socials.active i:hover {
        color: crimson;
        transform: scale(1.3);
    }

    .navbar.sticky .socials.active {
        background: none;
    }

    .navbar.sticky .socials.active i {
        color: white;
        cursor: pointer;
        font-size: 10vw;
        margin: 0 10px;
        transition: color 0.3s, transform 0.3s;
    }

    .navbar.sticky .socials.active i:hover {
        color: crimson;
    }
}