/* About.css */
.about {
    padding-bottom: 1em;
}

.about-content .column.left {
    width: 45%;
    padding-right: 3em;
}

.about-content .column.left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.about-content .column.right {
    width: 55%;
}

.about-content .column.right .text {
    font-size: clamp(12px, 1.4em, 25px);
}

.about-content .column.right p {
    text-align: justify;
}

/* Title */
.about-content .column.right div span {
    color: crimson;
}

/* Button */
.about-content .column.right a {
    display: inline-block;
    background: crimson;
    color: white;
    font-size: 1.3em;
    font-weight: 600;
    padding: 0.5em 1.2em;
    margin-top: 1.2em;
    border-radius: 6px;
    border: 2px solid crimson;
    transition: all 0.3s ease;
}

.about-content .column.right a:hover {
    color: crimson;
    background: none;
}

@media (max-width: 1100px) {
    .about-content .column.left img {
        height: 100%;
        min-height: 300px;
        width: 100%;
        min-width: 280px;
        object-fit: cover;
        border-radius: 15px;
    }
}

@media (max-width: 950px) {
    .about div {
        max-width: 800px;
    }

    .about-content {
        width: 100%;
    }

    .about-content .column.left {
        display: flex;
        justify-content: center;
        padding-right: 0px;
        margin: 0 auto 2em;
    }

    .about-content .column.right {
        flex: 100%;
    }
}